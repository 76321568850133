<script setup lang="ts">
import type { Game } from "@/types";

const props = defineProps<{
	game: Game;
}>();

const {
	public: { baseImageUrl }
} = useRuntimeConfig();

const imageUrl = computed(() => `${baseImageUrl}${props.game.img}`);
</script>

<template>
	<div v-if="game" class="game-promotion-image">
		<LazyNuxtImg :src="imageUrl" :alt="game?.title" height="120" width="96" format="avif" />
		<AText class="game-promotion-title" variant="topeka" :modifiers="['bold', 'uppercase', 'center']">
			<span v-html="game.title" />
		</AText>
	</div>
</template>
<style lang="scss" scoped>
$image-offset-x: 29px;
$image-offset-y: 50px;

.game-promotion-image {
	position: absolute;
	right: $image-offset-x;
	top: $image-offset-y;
	width: 96px;
	height: 120px;
	z-index: -1;

	img {
		position: relative;
		display: block;
		width: 100%;
		height: 100%;
		border-radius: 4px;
	}

	&:before {
		position: absolute;
		width: 328px;
		height: 257px;
		content: "";
		right: -$image-offset-x;
		top: -$image-offset-y;
		background: url("/nuxt-img/free-spin/promo.jpg");
	}
}

.game-promotion-title {
	position: absolute;
	bottom: 4px;
	left: 8px;
	right: 8px;
	z-index: 1;
	letter-spacing: 0.04em;
	line-height: 1;
	text-shadow: 0px 2.4px 2.4px var(--chuncheon);

	&:deep(span) {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 2px;

		& > span {
			font-size: 0.667em;
		}
	}
}
</style>
